import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import Breadcrumbs from '../components/Breadcrumbs';
import CaseStudySchema from '../components/CaseStudySchema';
import RelatedWorks from '../components/templates/RelatedWorks';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Helmet } from 'react-helmet';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import 'swiper/css';

const CasePost = ({ data }) => {
  const {
    title,
    slug,
    clientUrl,
    image,
    summary,
    body,
    category,
    industry,
    tags,
    seotitle,
    seodescription,
  } = data.contentfulCaseStudy;

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: '制作実績一覧', url: 'https://www.involve-in.jp/works' },
    { name: title, url: `https://www.involve-in.jp/works/${slug}` },
  ];

  return (
    <Layout>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <Helmet>
        <title>{seotitle || title}</title>
        <meta name="description" content={seodescription || summary} />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href={`https://www.involve-in.jp/works/${slug}`}
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="/images/common/favicon.ico"
        />

        {/* Open Graph (OGP: Facebook, LinkedIn) */}
        <meta property="og:title" content={seotitle || title} />
        <meta property="og:description" content={seodescription} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.involve-in.jp/works/${slug}`}
        />
        <meta
          property="og:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seotitle || title} />
        <meta name="twitter:description" content={seodescription} />
        <meta
          name="twitter:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />
      </Helmet>

      {/* ✅ JSON-LD を適用 */}
      <CaseStudySchema article={data.contentfulCaseStudy} />
      <article className="max-w-screen-lg w-full mx-auto py-32 px-6 bg-white bg-opacity-95 shadow-lg rounded-lg">
        <section className="container">
          <h2 className="font-roboto text-6xl md:text-[10rem] font-bold text-center mb-12 md:mb-20 text-black">
            WORKS
          </h2>

          <div className="mb-8">
            <h1 className="text-4xl font-bold text-black mb-12 ">{title}</h1>
            <div className="flex flex-wrap gap-4 mb-6">
              {category &&
                category.length > 0 &&
                category.map((cat) => (
                  <div
                    key={cat.slug}
                    className="border border-black px-4 py-2 text-2xl rounded-md"
                  >
                    {cat.name}
                  </div>
                ))}

              {industry &&
                industry.length > 0 &&
                industry.map((ind) => (
                  <div
                    key={ind.slug}
                    className="border border-black px-4 py-2 text-2xl rounded-md"
                  >
                    {ind.name}
                  </div>
                ))}
            </div>
            <p className="text-2xl text-black mb-12 ">{summary}</p>
          </div>

          <div className="flex flex-col gap-8">
            {image.length >= 2 ? (
              <div className="flex flex-col md:flex-row gap-4">
                {/* PCスクリーンショット */}
                <div className="md:w-1/2 w-full h-[400px] flex items-center justify-center bg-gray-100">
                  <GatsbyImage
                    image={getImage(image[0])}
                    alt="PC Screenshot"
                    imgStyle={{ objectFit: 'contain' }}
                    className="w-auto h-full rounded-lg shadow-md"
                  />
                </div>
                {/* スマホスクリーンショット */}
                <div className="md:w-1/2 w-full h-[400px] flex items-center justify-center bg-gray-100">
                  <GatsbyImage
                    image={getImage(image[1])}
                    alt="Mobile Screenshot"
                    imgStyle={{ objectFit: 'contain' }}
                    className="w-auto h-full rounded-lg shadow-md"
                  />
                </div>
              </div>
            ) : (
              <div className="w-full h-[400px] flex items-center justify-center bg-gray-100">
                <GatsbyImage
                  image={getImage(image[0])}
                  alt="Screenshot"
                  imgStyle={{ objectFit: 'contain' }}
                  className="w-auto h-full rounded-lg shadow-md"
                />
              </div>
            )}
          </div>
        </section>

        {/* ✅ 情報テーブル */}
        <section className="container mt-12 border-t border-b border-gray-400 text-black">
          <div className="flex justify-between py-4 border-b border-gray-400 text-2xl">
            <span className="font-bold w-1/4">WORKS</span>
            <span className="w-3/4 text-right">{title}</span>
          </div>
          {clientUrl && (
            <div className="flex justify-between py-4 border-b border-gray-400 text-2xl">
              <span className="font-bold w-1/4">LINK</span>
              <span className="w-3/4 text-right">
                <a
                  href={clientUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {clientUrl}
                </a>
              </span>
            </div>
          )}

          {tags && tags.length > 0 && (
            <div className="flex justify-between py-4 text-2xl">
              <span className="font-bold w-1/4">TAGS</span> {/* ← 左寄せ */}
              <span className="w-3/4 flex flex-wrap justify-end gap-2">
                {' '}
                {/* ← 右寄せ */}
                {tags.map((tag) => (
                  <span
                    key={tag.slug}
                    className="border border-black px-4 py-2 rounded-md"
                  >
                    {tag.name}
                  </span>
                ))}
              </span>
            </div>
          )}
        </section>

        {/* ✅ 説明文（スマホ時のレイアウト調整） */}
        <section className="container flex flex-col md:flex-row mt-12">
          <div className="text-2xl font-bold text-black md:w-1/3 mb-4 md:mb-0">
            DESCRIPTION
          </div>
          <div className="text-2xl leading-relaxed text-black md:w-2/3">
            {documentToReactComponents(JSON.parse(body.raw))}
          </div>
        </section>
        <RelatedWorks
          categories={category.map((cat) => cat.name)}
          industries={industry.map((ind) => ind.name)}
          excludeSlug={slug}
        />
      </article>
      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    contentfulCaseStudy(slug: { eq: $slug }) {
      title
      clientUrl
      image {
        gatsbyImageData(
          layout: CONSTRAINED
          formats: [AUTO, WEBP]
          placeholder: BLURRED
        )
      }
      summary
      body {
        raw
      }
      category {
        name
        slug
      }
      industry {
        name
        slug
      }
      tags {
        name
        slug
      }
      publishDate(formatString: "YYYY.MM.DD")
      slug
      seotitle
      seodescription
      author {
        name
        avatar {
          url
        }
        bio
      }
    }
  }
`;

export default CasePost;
