import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules'; // ✅ ナビゲーションのみ使用
import 'swiper/css';
import 'swiper/css/navigation'; // ✅ Swiper のナビゲーション用 CSS
import '../../styles/swiper-custom.css';

const RelatedWorks = ({ categories, industries, excludeSlug }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCaseStudy {
        nodes {
          title
          slug
          category {
            name
          }
          industry {
            name
          }
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              formats: [AUTO, WEBP]
              placeholder: BLURRED
            )
          }
        }
      }
    }
  `);

  // ✅ 関連する制作実績のフィルタリング（カテゴリ・インダストリー）
  const relatedWorks = data.allContentfulCaseStudy.nodes
    .filter(
      (work) =>
        work.slug !== excludeSlug &&
        (work.category?.some((cat) => categories.includes(cat.name)) ||
          work.industry?.some((ind) => industries.includes(ind.name)))
    )
    .slice(0, 6); // 🔥 最大6件まで表示！

  if (relatedWorks.length === 0) return null; // 関連がなければ非表示

  return (
    <section className="mt-12 relative">
      <h2 className="text-3xl font-bold mb-6 text-center">
        他の制作実績もチェック
      </h2>

      {/* ✅ Swiper に変更 */}
      <Swiper
        spaceBetween={16}
        slidesPerView={1.2}
        centeredSlides={true}
        breakpoints={{
          640: { slidesPerView: 2.2 },
          1024: { slidesPerView: 3.5 },
        }}
        loop={true}
        grabCursor={true}
        speed={600}
        navigation={true} // ✅ Swiper のナビゲーションを有効化
        modules={[Navigation]} // ✅ Swiper にナビゲーションを追加
      >
        {relatedWorks.map((work) => (
          <SwiperSlide key={work.slug}>
            <Link
              to={`/works/${work.slug}`}
              className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-2xl"
            >
              <div className="w-full aspect-[16/9] bg-gray-100 flex items-center justify-center">
                {work.image && work.image.length > 0 && (
                  <GatsbyImage
                    image={getImage(work.image[0])}
                    alt={work.title}
                    className="w-full h-auto"
                  />
                )}
              </div>
              <div className="p-6 text-center bg-black text-white">
                <h3 className="text-2xl font-semibold">{work.title}</h3>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default RelatedWorks;
