import React from 'react';
import { Helmet } from 'react-helmet';

const CaseStudySchema = ({ article }) => {
  if (!article || !article.author) {
    return null;
  }

  const authorData = {
    '@type': 'Person',
    name: article.author.name,
    url: '', // 必要なら Contentful から `profileUrl` を追加
    image: article.author.avatar?.url || '',
  };

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article', // ✅ `Article` を適用
    headline: article.title,
    datePublished: article.publishDate,
    author: authorData,
    publisher: {
      '@type': 'Organization',
      name: 'INVOLVE',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.involve-in.jp/images/logo.png',
      },
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `https://www.involve-in.jp/case/${article.slug}`,
    },
    image: article.image?.[0]?.gatsbyImageData?.images?.fallback?.src || '', // ✅ 最初の画像を JSON-LD に適用
    description: article.summary,
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default CaseStudySchema;
